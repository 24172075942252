// @flow
import * as React from 'react';
import {
  useGetComponent,
} from '@haaretz/htz-components';

type Props = {
  content: Array<Object>,
};


function HeaderSlot({ content, }: Props): React.Node {
  const getElements = useGetComponent();
  if (content) {
    return content.map(element => {
      const Element = getElements(element.inputTemplate, {
        ...element,
        loadPriority: element.loadPriority,
        isExpanded: element.isExpanded,
        preventRender: element.preventRender,
      });
      return (
        <Element
          key={element.contentId}
          {...element}
          {...(element.inputTemplate === 'PageNavigation'
            ? {
              pageType: 'section',
              // rowBgc: 'transparent',
            }
            : {})}
        />
      );
    });
  }
  return null;
}

export default HeaderSlot;
