// @flow
/* globals window,document */
import React from 'react';
import { GetComponentProvider, WidePageLayout, } from '@haaretz/htz-components';

import SlotsLayout from '../components/SectionPage/SlotsLayout';
import getElements from '../utils/getSectionPageElementsBS';

type Props = {
  url: {
    pathname: string,
    asPath: string,
    query: {
      path: string,
      isBot: boolean,
      isSmadar: boolean,
    },
  },
  responseWriteHead: ?Function,
  responseEnd: ?Function,
};

function SectionPage({ url, responseWriteHead, responseEnd, }: Props) {
  const path = (url && url.query.path) || '/';
  const asPath = url ? url.asPath : '';
  const { isBot, } = url.query;
  return (
    <GetComponentProvider value={getElements}>
      <WidePageLayout
        isBot={isBot}
        path={path}
        asPath={asPath}
        responseWriteHead={responseWriteHead}
        responseEnd={responseEnd}
        render={({ globalLazyload, }) => (
          <SlotsLayout globalLazyload={globalLazyload} path={path} />
        )}
      />
    </GetComponentProvider>
  );
}

SectionPage.defaultProps = {
  responseWriteHead: null,
  responseEnd: null,
};

export default SectionPage;
